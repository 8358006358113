import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'pin_real_estate';
const namespace = 'ui-pdp-icon';

const PinRealEstate = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="38"
    viewBox="0 0 30 38"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

PinRealEstate.propTypes = {
  className: string,
};

PinRealEstate.defaultProps = {
  className: null,
};

PinRealEstate.ICON_ID = ICON_ID;

export default React.memo(PinRealEstate);
export { PinRealEstate };
